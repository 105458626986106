@keyframes scrollVertical {
    0%{
        transform: translateX(5%);
    }
    50%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(5%);
    }
}

.announcementBar{
    animation: scrollVertical 5s infinite;
}
@import "./fonts.css";
@tailwind base;

@layer base {
  :root {
    /* font-size: 12px; */
  }
  html {
    margin: 0;
    padding: 0;
    font-family: "TT Hoves";
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .grecaptcha-badge * { 
    opacity:0 !important;
    visibility: hidden !important; 
  }

}

@tailwind components;
@layer components {

  /* ? misc */
  /* "active-map-piece absolute top-[147px]  left-[52px]", */
  .active-map-piece {
    @apply ease-out cursor-pointer fill-thrive-lime-green focus:fill-thrive-india-green active:fill-thrive-india-green hover:fill-thrive-india-green stroke-thrive-india-green;
  }
  .map-piece {
    @apply ease-out cursor-pointer stroke-1 color-transition-300 fill-thrive-pale-green focus:fill-thrive-lime-green active:fill-thrive-lime-green hover:fill-thrive-lime-green stroke-thrive-india-green;
  }

  a {
    @apply duration-300 ease-in-out hover:text-thrive-lime-green focus:text-thrive-lime-green active:text-thrive-lime-green;
  }

  mark{
    @apply bg-thrive-nyanza-green;
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
  }

  .react-multi-carousel-list {
    padding: 0px 100px;
    @apply border-4;
  }
  .carousel-container {
    padding: 10px;
  }

  /* ? header components */
  .main-header {
    height: theme("height[main-header]")
  }

  /* ? navigation components */

  .top-nav-container {
    @apply relative flex flex-row items-center justify-center;
  }
  .top-nav {
    @apply hidden md:self-end md:flex md:flex-row md:items-center md:justify-center;
  }
  .mobile-top-nav {
    @apply fixed top-0 left-0 right-0 z-50 flex-col w-screen mt-20 bg-white md:hidden;
  }
  .mobile-top-nav--isOpen {
    @apply flex md:hidden;
  }
  .mobile-top-nav--isClosed {
    @apply hidden;
  }

  /* ? rich text componenet */ 

  /* heading component default classes */
  .heading-1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 120%;
    letter-spacing: -2%;
  }
  .heading-2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0%;
  }
  .heading-3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0%;
  }
  .heading-4 {
    font-weight: 600;
    font-size: 27px;
    line-height: 120%;
    letter-spacing: 0%;
  }
  .heading-5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0%;
  }
  .heading-6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0%;
  }

  .paragrapgh {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0%;
  }

}
@tailwind utilities;
@layer utilities {
  .color-transition-ease-in-out {
    @apply transition-colors duration-300 hover:ease-in-out;
  }
  .color-transition-700 {
    @apply transition-colors duration-700;
  }
  .color-transition-500 {
    @apply transition-colors duration-500;
  }
  .color-transition-300 {
    @apply transition-colors duration-300; 
  }
  .hover-ease-in-out {
    @apply hover:ease-in-out;
  }
  .hover-ease-in {
    @apply hover:ease-in;
  }
  .hover-ease-out {
    @apply hover:ease-out;
  }

  div[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }
}

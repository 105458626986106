.tabs {
  display: flex;
  /* flex-direction: row; */
  height: 70px;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  overflow-x: scroll;
}

.sticky {
  position: fixed;
  width: 100%;
  z-index: 20;
  border-bottom: 1px solid #e2e2e2;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  @apply top-20;
}

.tab-link {
  list-style: none;
  /* font-size: 20px; */
  color: #7a7a7a;
  margin-right: 50px;
  cursor: pointer;
  @apply transition duration-300 ease-in-out;
  @apply text-xs md:text-base lg:text-lg;
}
.tab-link::after{
  height: 2px;
  background-color: transparent;
  content: '';
  display: block;
  width: 0;
  transition: width .7s ease-in-out;
}
.tab-link:hover{
  color: #000000 !important;
}
.tab-link.active {
  color: #000000 !important;
  /* font-size: 20px; */
  /* padding-top: 1px; */
}
.tab-link.active::after{
  width: 100%;
  background-color: #48a928;

}


/* @media screen and (min-width: 800px) {
  .tabs p {
    font-size: 14px;
  }
} */
